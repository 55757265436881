import React from 'react';
import Link from 'next/link';
import MenuItem from '@mui/material/MenuItem';
import {
  VerificationStatusTrueIcon,
  VerificationStatusFalseIcon,
  AccountIcon,
  LogoutIcon,
  LoginIcon,
} from 'theme/icons';
import styles from './HeaderUserMenu.module.scss';
import { ensureUrl } from 'utils';
import { HeaderUserMenuProps } from './HeaderUserMenu.interface';

export const HeaderUserMenu: React.FC<HeaderUserMenuProps> = ({
  data,
  titleMap,
  masqueraded,
  unmasqueradeHandler,
  auth,
  redirectTo,
  handleUserClose,
}) => {
  return (
    <div className={styles.newHeaderUserMenu}>
      <div className={styles.newHeaderUserMenuTop}>
        <div className={styles.newHeaderUserMenuUsername}>
          {data?.first_name} {data?.last_name}{' '}
          {!!data &&
            (data.verified ? (
              <VerificationStatusTrueIcon className='icon' />
            ) : (
              <VerificationStatusFalseIcon className='icon' />
            ))}
        </div>
        <div className={styles.newHeaderUserMenuInfo}>
          <b>MEMBER ID:</b>
          {data?.member_id}
        </div>
        {data?.travel_code && data?.plan_type && (
          <div className={styles.newHeaderUserMenuInfo}>
            <b>Travel Code:</b>
            {data.travel_code}
          </div>
        )}
        <div className={styles.newHeaderUserMenuInfo}>
          <b>Travel protection:</b>
          {data?.travel_protection ? 'Active' : 'Inactive'}
        </div>
        <div className={styles.newHeaderUserMenuInfo}>
          <b>Plan:</b>
          {data?.plan_type ? titleMap[data.plan_type] : 'None'}
        </div>
      </div>
      <div className={styles.newHeaderUserMenuBottom}>
        <div className={styles.newHeaderUserMenuItems}>
          <Link href={`${ensureUrl(process.env.NEXT_PUBLIC_ACCOUNT_APP_URL!)}/profile`}>
            <MenuItem className={styles.newHeaderUserMenuItem} onClick={handleUserClose}>
              <AccountIcon className='icon' />
              My Account
            </MenuItem>
          </Link>
          {masqueraded && (
            <Link href='#'>
              <MenuItem className={styles.newHeaderUserMenuItem} onClick={handleUserClose}>
                <div onClick={unmasqueradeHandler}>
                  <LoginIcon className='icon' />
                  Unmasquerade
                </div>
              </MenuItem>
            </Link>
          )}
          <MenuItem
            className={styles.newHeaderUserMenuItem}
            onClick={async () => {
              if (!auth || !redirectTo) {
                return;
              }
              await auth().signOut();
              redirectTo('auth/signout', {
                return_to: window.location.href,
              });
            }}
          >
            <LogoutIcon className='icon' />
            Logout
          </MenuItem>
        </div>
      </div>
    </div>
  );
};

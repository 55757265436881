import React from 'react';
import { WeSaluteLogo } from 'theme/icons';
import styles from './HeaderLogo.module.scss';

export const HeaderLogo: React.FC = () => {
  return (
    <div className={styles.newHeaderLogoWrapper}>
      <WeSaluteLogo className={styles.newHeaderLogo} />
    </div>
  );
};

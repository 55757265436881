import React from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import styles from './HeaderMenuItem.module.scss';
import { HeaderMenuItemProps } from './HeaderMenuItem.interface';

export const HeaderMenuItem: React.FC<HeaderMenuItemProps> = ({ label, href, domain }) => {
  const router = useRouter();
  return (
    <>
      {href.indexOf(domain) == -1 ? (
        //Internal links
        <Link href={href}>
          <a className={`${styles.newHeaderMenuItem} ${router.pathname == href ? 'active' : ''}`}>{label}</a>
        </Link>
      ) : (
        //External links
        <a className={styles.newHeaderMenuItem} href={href}>
          {label}
        </a>
      )}
    </>
  );
};

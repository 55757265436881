import React from 'react';
import Link from 'next/link';
import MenuItem from '@mui/material/MenuItem';
import {
  VerificationStatusTrueIcon,
  VerificationStatusFalseIcon,
  AccountIcon,
  LogoutIcon,
  LoginIcon,
} from 'theme/icons';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import styles from './HeaderMobileNavbar.module.scss';
import { HeaderMobileNavbarProps } from './HeaderMobileNavbar.interface';
import { ensureUrl } from 'utils';
import cn from 'classnames';

export const HeaderMobileNavbar: React.FC<HeaderMobileNavbarProps> = ({
  openHeaderMobileMenu,
  data,
  titleMap,
  masqueraded,
  unmasqueradeHandler,
  auth,
  redirectTo,
  hideUserMenu,
  anonymous,
  signUpUrl,
  loginUrl,
  headerItemsBlock,
}) => {
  return (
    <div className={cn(styles.newHeaderMobileNavbar, { [styles.newHeaderMobileNavbarOpen]: openHeaderMobileMenu })}>
      <div className={styles.newHeaderUserMenu}>
        {!hideUserMenu && (
          <>
            <div className={styles.newHeaderUserMenuTop}>
              <div className={styles.newHeaderUserMenuUsername}>
                {data?.first_name} {data?.last_name}{' '}
                {!!data &&
                  (data.verified ? (
                    <VerificationStatusTrueIcon className='icon' />
                  ) : (
                    <VerificationStatusFalseIcon className='icon' />
                  ))}
              </div>
              <div className={styles.newHeaderUserMenuInfo}>
                <b>MEMBER ID:</b>
                {data?.member_id}
              </div>
              {data?.travel_code && data?.plan_type && (
                <div className={styles.newHeaderUserMenuInfo}>
                  <b>Travel Code:</b>
                  {data.travel_code}
                </div>
              )}
              <div className={styles.newHeaderUserMenuInfo}>
                <b>Travel protection:</b>
                {data?.travel_protection ? 'Active' : 'Inactive'}
              </div>
              <div className={styles.newHeaderUserMenuInfo}>
                <b>Plan:</b>
                {data?.plan_type ? titleMap[data.plan_type] : 'None'}
              </div>
            </div>
          </>
        )}
        <div className={styles.newHeaderUserMenuBottom}>
          {!anonymous && (
            <div className={styles.newHeaderUserMenuItems}>
              <Link href={`${ensureUrl(process.env.NEXT_PUBLIC_ACCOUNT_APP_URL!)}/profile`}>
                <MenuItem className={styles.newHeaderUserMenuItem}>
                  <AccountIcon className='icon' />
                  My Account
                </MenuItem>
              </Link>
              {masqueraded && (
                <Link href='#'>
                  <MenuItem className={styles.newHeaderUserMenuItem}>
                    <div onClick={unmasqueradeHandler}>
                      <LoginIcon className='icon' />
                      Unmasquerade
                    </div>
                  </MenuItem>
                </Link>
              )}
              <MenuItem
                className={styles.newHeaderUserMenuItem}
                onClick={async () => {
                  if (!auth || !redirectTo) {
                    return;
                  }
                  await auth().signOut();
                  redirectTo('auth/signout', {
                    return_to: window.location.href,
                  });
                }}
              >
                <LogoutIcon className='icon' />
                Logout
              </MenuItem>
            </div>
          )}
          {anonymous && (
            <div className={styles.newHeaderUserMenuItems}>
              <Link href={signUpUrl}>
                <MenuItem className={styles.newHeaderUserMenuItem}>
                  <PersonAddIcon className='icon' />
                  Sign Up
                </MenuItem>
              </Link>
              <Link href={loginUrl}>
                <MenuItem className={styles.newHeaderUserMenuItem}>
                  <VpnKeyIcon className='icon' />
                  Login
                </MenuItem>
              </Link>
            </div>
          )}
        </div>
      </div>
      {headerItemsBlock && <nav className={styles.newHeaderMobileNav}>{headerItemsBlock}</nav>}
    </div>
  );
};

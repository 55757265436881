import React, { useContext, useState, useEffect } from 'react';
import { UseStyles } from 'hooks';
import AppshellContext from '@inc/AppshellContext';
import Typography from '@mui/material/Typography';
import { useRouter } from 'next/router';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import { MemberInfoOutput } from 'services/api/client';
import { useMemberApi } from 'hooks/useMemberApi';
import Cookies from 'services/Cookies';
import PersonIcon from '@mui/icons-material/Person';
import { ensureUrl } from 'utils';
import { HeaderLogo } from '@ui/atoms/HeaderLogo/HeaderLogo';
import { HeaderMenuItem } from '@ui/atoms/HeaderMenuItem/HeaderMenuItem';
import { HeaderLoginMenu } from '@ui/molecules/HeaderLoginMenu/HeaderLoginMenu';
import { HeaderUserMenu } from '@ui/molecules/HeaderUserMenu/HeaderUserMenu';
import { HeaderMobileNavbar } from '@ui/molecules/HeaderMobileNavbar/HeaderMobileNavbar';

const domain = ensureUrl(process.env.NEXT_PUBLIC_PLATFORM_APP_URL || '');

export function HeaderDefault({
  showMenu = false,
  hideUserMenu,
  anonymous,
}: {
  showMenu?: boolean;
  hideUserMenu?: boolean;
  anonymous?: boolean;
}): JSX.Element {
  const classes = UseStyles();
  const [openHeaderMobileMenu, setOpenHeaderMobileMenu] = useState(false);
  const [anchorUserMenuEl, setAnchorUserMenuEl] = useState<HTMLElement | null>(null);
  const userContext = useContext(AppshellContext);
  const { data } = useMemberApi<MemberInfoOutput>('memberInfo');
  const router = useRouter();
  const openUserMenu = Boolean(anchorUserMenuEl);
  const user = userContext?.user?.data;
  const auth = userContext?.auth;
  const redirectTo = userContext?.goto;

  const handleUserMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorUserMenuEl(event.currentTarget);
  };

  const handleUserClose = () => {
    setAnchorUserMenuEl(null);
  };

  const masqueraded = !!Cookies.get('masquerade') || !!data?.masqueraded;
  const unmasqueradeHandler = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    const masqueraded_to = Cookies.get('masquerade');
    if (masqueraded_to) {
      Cookies.set('unmasquerade_track', masqueraded_to);
    }
    Cookies.remove('masquerade');
    window.location.href = ensureUrl(`${process.env.NEXT_PUBLIC_ADMIN_APP_URL}/unmasquerade`);
  };

  const HeaderItemsBlock = () => {
    const marketingUrl = ensureUrl(process.env.NEXT_PUBLIC_MARKETING_APP_URL!);
    return (
      <>
        <HeaderMenuItem domain={domain} label='About' href={`${marketingUrl}/about`} />
        <HeaderMenuItem domain={domain} label='Plans & Pricing' href={`${marketingUrl}/plans`} />
        <HeaderMenuItem domain={domain} label='Blog' href={`${marketingUrl}/blog`} />
        <HeaderMenuItem domain={domain} label='Benefits' href={`${marketingUrl}/benefits`} />
        <HeaderMenuItem domain={domain} label='Marketplace' href={`${marketingUrl}/marketplace`} />
      </>
    );
  };

  const signUpUrl = `${ensureUrl(process.env.NEXT_PUBLIC_AUTH_APP_URL!)}?return_to=${
    window.location.origin + router.asPath
  }`;

  const loginUrl = `${ensureUrl(process.env.NEXT_PUBLIC_AUTH_APP_URL!)}/signin?return_to=${
    window.location.origin + router.asPath
  }`;

  // Add body class for account pages.
  useEffect(() => {
    document.body.classList.add('page-account');
    return () => {
      document.body.classList.remove('page-account');
    };
  }, []);
  const titleMap = {
    year: 'Annual',
    month: 'Monthly',
    trial: 'Trial',
    '3year': '3 Year',
    '5year': '5 Year',
    lifetime: 'Lifetime',
  };
  if (!user && !anonymous) {
    return <></>;
  }
  return (
    <>
      <Typography component='div' variant='body2' className={classes.newHeaderWrapper}>
        <div className={classes.newHeaderSpace} />
        <div className={classes.newHeader}>
          <div className={classes.newHeaderInner}>
            <HeaderLogo />
            {showMenu && (
              <nav className={classes.newHeaderNav}>
                <HeaderItemsBlock />
              </nav>
            )}
            {hideUserMenu && anonymous && <HeaderLoginMenu signUpUrl={signUpUrl} loginUrl={loginUrl} />}
            {!hideUserMenu && (
              <>
                <div className={classes.newHeaderDesktopUserMenu}>
                  <Button
                    aria-controls='user-menu'
                    aria-haspopup='true'
                    className={classes.newHeaderDesktopUserMenuBtn}
                    onClick={handleUserMenuClick}
                  >
                    <PersonIcon className={classes.newHeaderDesktopUserMenuIcon} />
                  </Button>
                  <Menu
                    id='user-menu'
                    PopoverClasses={{
                      paper: classes.newHeaderDesktopUserMenuPaper,
                    }}
                    MenuListProps={{ className: classes.newHeaderDesktopUserMenuList }}
                    keepMounted
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    anchorEl={anchorUserMenuEl}
                    open={openUserMenu}
                    onClose={handleUserClose}
                  >
                    <HeaderUserMenu
                      data={data}
                      titleMap={titleMap}
                      masqueraded={masqueraded}
                      unmasqueradeHandler={unmasqueradeHandler}
                      auth={auth}
                      redirectTo={redirectTo}
                      handleUserClose={handleUserClose}
                    />
                  </Menu>
                </div>
              </>
            )}
            <Button
              onClick={() => {
                setOpenHeaderMobileMenu((prev) => !prev);
              }}
              className={`${classes.newHeaderToggle} ${openHeaderMobileMenu ? 'active' : ''}`}
            >
              <span className='sr-only'>Toggle navigation</span>
              <span className='icon-bar'></span>
              <span className='icon-bar'></span>
              <span className='icon-bar'></span>
            </Button>
            <HeaderMobileNavbar
              openHeaderMobileMenu={openHeaderMobileMenu}
              data={data}
              titleMap={titleMap}
              masqueraded={masqueraded}
              unmasqueradeHandler={unmasqueradeHandler}
              auth={auth}
              redirectTo={redirectTo}
              hideUserMenu={!!hideUserMenu}
              anonymous={!!anonymous}
              signUpUrl={signUpUrl}
              loginUrl={loginUrl}
              headerItemsBlock={showMenu && <HeaderItemsBlock />}
            />
          </div>
        </div>
      </Typography>
    </>
  );
}

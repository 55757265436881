import React from 'react';
import Link from 'next/link';
import styles from './HeaderLoginMenu.module.scss';
import { HeaderLoginMenuProps } from './HeaderLoginMenu.interface';

export const HeaderLoginMenu: React.FC<HeaderLoginMenuProps> = ({ signUpUrl, loginUrl }) => {
  return (
    <div className={styles.newHeaderDesktopLoginMenu}>
      <Link href={signUpUrl}>
        <a className={styles.newHeaderLoginItem}>Sign Up</a>
      </Link>
      <Link href={loginUrl}>
        <a className={styles.newHeaderLoginItem}>Login</a>
      </Link>
    </div>
  );
};
